export const FACTORY_ADDRESS = '0xE7aE959bbC94BDF2E9FF28a214840aB3285d7765'

export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000'

export const POOL_INIT_CODE_HASH = '0x8aeb7d9c447c5c3f6ad05ba4da15b42c982c77c5791901c09fef871b1a52e2e2'

/**
 * The default factory enabled fee amounts, denominated in hundredths of bips.
 */
export enum FeeAmount {
  LOWEST = 100,
  LOW = 500,
  LOWER_MEDIUM = 1000,
  MEDIA_MEDIUM = 2000,
  MEDIUM = 3000,
  HIGH = 10000
}

/**
 * The default factory tick spacings by fee amount.
 */
export const TICK_SPACINGS: { [amount in FeeAmount]: number } = {
  [FeeAmount.LOWEST]: 1,
  [FeeAmount.LOW]: 10,
  [FeeAmount.LOWER_MEDIUM]: 20,
  [FeeAmount.MEDIA_MEDIUM]: 40,
  [FeeAmount.MEDIUM]: 60,
  [FeeAmount.HIGH]: 200
}
